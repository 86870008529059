body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* App.css */


.App-header {
  background-color: #282c34;
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  font-size: calc(10px + 2vmin);
  color: gray;
}

.App-link {
  color: #61dafb;
}
/* container */
@media only screen and (max-width: 601px){
.container {
    width: 100%;
}
}

/* fix menu */
@media only screen and (min-width: 601px) {
  nav .nav-wrapper  i {
     height: initial;
     line-height: inherit;
 }
 i.fas.fa-sign-out-alt {
  font-size: 14px;
}

}
nav .brand-logo i {
  float: inherit !important;
  
}

/* products list */
.product-item em {
  font-size: larger;
  display: block;
  cursor: pointer;
}
.product-item:nth-of-type(2n) {
  background-color: whitesmoke;
}
.product-item {
  padding: 10px;
  border-bottom: 1px solid;
}

/* busket list */
.busket-item summary {
  cursor: pointer;
  font-size: larger;
  margin-bottom: 8px;
}
.busket-item.card {
  padding: 10px;
  /* background-color: gray; */
}
.busket-details a {
  margin-top: 8px;
}
.busket-item details p {
  border-bottom: 1px solid;
  margin: 0;
}

/* pagination */
nav.pagination ul li {
  float: none;
}
.pagination li.current-page {
  background-color: aquamarine;
  height: auto;
}
nav.pagination {
  /* width: max-content; */
  margin: 20px auto;
  background-color: #E0E2D3;
  border-radius: 39px;
}

/* register */
.register .card,
.login .card {
  padding: 10px;
}
@media only screen and (max-width: 601px) {
  .register .card,
  .login .card {
    margin: 0;
  }
}

/* alerts */
.toast.alert-info {
  background-color: yellowgreen;
  color: black;
}
.toast.alert-error {
  background-color: red;
  color: black;
}
.toast.alert-success {
  background-color: green;
}
/* productSearch form */
#productSearch {
  /* display: flex;
  align-items: center;  */
}
div#productSearch .row {
  margin-bottom: 0;
}
div#productSearch button.btn.btn-primary {
  /* margin-bottom: 0; */
  margin-top: 20px;
  /* margin: auto; */
}
div#productSearch {
    border-top: 1px solid #c8a5a5;
    border-radius: 38px;
    background-color: #E0E2D3;
}

